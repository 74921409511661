import React from 'react';
import './App.css';
import CreditCardForm from "./components/CreditCardForm/CreditCardForm";

const qs = require('qs');

class App extends React.Component {
    constructor(props) {
        super(props);
        window.Chargebee.init({
            site: "ergatta-test",
            publishableKey: "test_05CoYvqT0JF4qzos5ghwc81iDMajBOeA"
        });

        this.state = {
            deviceId: "",
            apiBase: "",
        };

        let query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        // eslint-disable-next-line array-callback-return
        Object.entries(query).map(item => this.state[item[0]] = item[1]);
        // console.log(this.state);

    }

    render() {
        return (
            <div className="App">
                <CreditCardForm deviceId={this.state.deviceId}
                                apiBase={this.state.apiBase}
                />
            </div>
        )
    }
}

export default App;
